<template>
  <div class="bg-light card-xxl-stretch mb-5 mb-xl-10 strip-bg">
    <div class="card-body p-3">
      <div class="row gy-5 g-xl-8">
        <div class="col-xl-8">
          <div class="card-p position-relative">
            <!--begin::Row-->
            <div class="row">
              <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-people.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countRelawan }}
                  </span>
                </span>
                <span class="fs-1 text-white">Relawan Terdaftar</span>
              </div>
              <!-- <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-presention-chart.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countActivity }}
                  </span>
                </span>
                <span class="fs-1 text-white">Aktivitas Relawan</span>
              </div> -->
              <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-user-tag-1.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countRumah }}
                  </span>
                </span>
                <span class="fs-1 text-white">Sapa Rakyat</span>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-user-tag-1.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countRumah }}
                  </span>
                </span>
                <span class="fs-1 text-white">Rumah Terkunjungi</span>
              </div> -->
              <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-user-tag-1.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countSapatokoh }}
                  </span>
                </span>
                <span class="fs-1 text-white">Sapa Tokoh</span>
              </div>
              <!-- <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-user-tag-1.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countJaringtokoh }}
                  </span>
                </span>
                <span class="fs-1 text-white">Jaring Tokoh</span>
              </div> -->
            </div>
            <div class="row">
              <!-- <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-user-tag-1.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countJaringtokoh }}
                  </span>
                </span>
                <span class="fs-1 text-white">Jaring Tokoh</span>
              </div> -->
              <!-- <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-user-tag-1.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countKolektif }}
                  </span>
                </span>
                <span class="fs-1 text-white">Sapa Rakyat Kolektif</span>
              </div> -->
              <!-- <div class="col-md-5 col-sm-12 bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span class="svg-icon-4x svg-icon-white d-block mb-3 mt-n5">
                  <inline-svg :src="path + 'other/svg/vuesax-bold-user-tag-1.svg'" />
                  <span class="fsx-1 text-white fw-bold me-2 float-end">
                    {{ countTokoh }}
                  </span>
                </span>
                <span class="fs-1 text-white">Sapa Tokoh Event</span>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <widget-3 v-if="activity && activity.length" widget-classes="shadow mt-xxl-7 mb-5 mb-xl-10 me-xxl-5"
            :widget-data="activity" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
// import Widget1 from "@/components/custom/resume/widget1.vue";
// import Widget2 from "@/components/custom/resume/widget2.vue";
import Widget3 from "@/components/custom/resume/widget3.vue";
// import Widget4 from "@/components/custom/resume/widget4.vue";
import { useStore } from "vuex";

export default defineComponent({
  // components: { Widget1, Widget3, Widget2, Widget4 },
  components: { Widget3 },
  setup() {
    setCurrentPageTitle("Resume");

    const store = useStore();

    store.commit("SET_ACTIVE_MENU", "resume");
    store.dispatch("AllActivityDashboard");
    store.dispatch("getDashboardResume");

    const path = window.location.origin + "/";

    const activity = computed(() => store.state.ActivityDashboardModule.all);
    const countRelawan = computed(() => store.state.ResumeModule.relawan);
    const countActivity = computed(() => store.state.ResumeModule.activity);
    const countRumah = computed(() => store.state.ResumeModule.rumah);
    const countBaliho = computed(() => store.state.ResumeModule.baliho);
    const countSapatokoh = computed(() => store.state.ResumeModule.sapatokoh);
    const countJaringtokoh = computed(() => store.state.ResumeModule.jaringtokoh);
    const countKolektif = computed(() => store.state.ResumeModule.kolektif);
    const countTokoh = computed(() => store.state.ResumeModule.tokoh);

    return {
      path,
      activity,
      countRelawan,
      countActivity,
      countRumah,
      countBaliho,
      countSapatokoh,
      countJaringtokoh,
      countKolektif,
      countTokoh,
    };
  },
});
</script>

<style>
.timeline-label::before {
  left: 1px !important;
}
</style>
